<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        class="ma-0 primary white--text font-weight-black headline"
        style="overflow: hidden"
      >
        <span class="ml-1">Dealer Records</span>
      </v-card-title>
      <v-card-text class="pt-4 px-1">
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-data-table
                :headers="headers"
                :items="titles"
                :search="search"
                item-key="id"
                dense
                :loading="loading"
                :items-per-page="25"
                :footer-props="{
                  itemsPerPageOptions: [25, 50, 100],
                }"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Search Dealer Records"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <template v-slot:item.date="{ item }">
                  {{ item.date }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    x-small
                    color="primary"
                    :loading="downloadingBos === item.id"
                    :disabled="downloadingBos === item.id"
                    @click="downloadBosPage(item, 2)"
                    class="mr-1"
                  >
                    BOS
                  </v-btn>
                  <v-btn
                    x-small
                    color="primary"
                    :loading="downloadingBos === item.id"
                    :disabled="downloadingBos === item.id"
                    @click="downloadBosPage(item, 3)"
                    class="mr-1"
                  >
                    3-Day Removal
                  </v-btn>
                  <v-btn
                    x-small
                    color="primary"
                    :loading="downloadingBos === item.id"
                    :disabled="downloadingBos === item.id"
                    @click="downloadBosPage(item, 4)"
                    class="mr-1"
                  >
                    Odometer Discl.
                  </v-btn>
                  <v-btn
                    x-small
                    color="success"
                    :loading="downloadingImage === item.id"
                    :disabled="downloadingImage === item.id"
                    @click="viewTitleImage(item)"
                  >
                    View Title
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import fb from "@/main";
import moment from "moment";

export default {
  name: "DealerRecords",
  data() {
    return {
      search: "",
      titles: [],
      headers: [
        { text: "Date", value: "date" },
        { text: "Name", value: "name" },
        { text: "Year", value: "vehicleYear" },
        { text: "Make", value: "vehicleMake" },
        { text: "Model", value: "vehicleModel" },
        { text: "VIN", value: "vehicleVin" },
        { text: "", value: "actions", width: "400", align: "end", sortable: false },
      ],
      loading: true, // Add loading state
      downloadingBos: null, // Track which item is being downloaded
      downloadingImage: null, // Track which item is being downloaded
    };
  },
  async created() {
    await this.fetchDealerTitles();
    this.loading = false; // Set loading to false after data is fetched
  },
  methods: {
    async fetchDealerTitles() {
      const response = await fb.db.collection("titleRequests").get();
      const cutoffDate = moment("2024-10-01", "YYYY-MM-DD"); // Changed from 09-01 to 10-01
      this.titles = response.docs
        .map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            name: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
            date: data.createDate ? moment(data.createDate.toDate()) : null,
            titleFile: data.uploads?.find((file) => file.startsWith("title-")) || null,
          };
        })
        .filter((item) => item.titleFile && item.status === "completed" && item.date && item.date.isAfter(cutoffDate))
        .sort((a, b) => b.date - a.date)
        .map((item) => ({
          ...item,
          date: item.date.format("MM/DD/YYYY"),
        }));
    },
    async downloadBosPage(item, page) {
      this.downloadingBos = item.id;
      try {
        const getPageFromJotformPdfCall = fb.functions.httpsCallable("getPageFromJotformPdf");
        const response = await getPageFromJotformPdfCall({
          jotFormFormId: item.jotFormFormId,
          jotFormSubmissionId: item.jotFormSubmissionId,
          filenameBos: item.filenameBos,
          vehicleVin: item.vehicleVin,
          page,
        });

        const pdfBytes = Uint8Array.from(atob(response.data.pdfBase64), (c) => c.charCodeAt(0));
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        // Open in new tab instead of downloading
        window.open(url, '_blank');
      } catch (e) {
        alert(`Error: ${e.message}`);
      } finally {
        this.downloadingBos = null;
      }
    },
    async viewTitleImage(item) {
      this.downloadingImage = item.id;
      try {
        const storageRef = fb.storage.ref(`uploads/${item.zip}/${item.vehicleVin}`);
        const listResult = await storageRef.listAll();

        // Filter files that start with "title-"
        const titleFiles = listResult.items.filter((file) => file.name.startsWith("title-"));

        if (titleFiles.length === 0) {
          alert("No title images found.");
          return;
        }

        // Download each title file in memory, add extension, and load it
        for (const file of titleFiles) {
          const filename = file.name;
          const url = await file.getDownloadURL();

          if (url) {
            // Fetch the file as a blob
            const response = await fetch(url);
            const blob = await response.blob();

            // Create a URL for the blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a temporary link to trigger the download
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename; // Add the correct extension
            link.click();

            // Clean up by revoking the blob URL
            window.URL.revokeObjectURL(blobUrl);
          } else {
            alert(`Unable to fetch file: ${file.name}`);
          }
        }
        this.downloadingImage = null;
      } catch (e) {
        alert(`Error: ${e.message}`);
      }
    },
  },
};
</script>
