<template>
    <div>
        <v-select
            dense
            v-model="imageClassification"
            :items="['Title', 'VIN']"
            label="Image Classification"
            outlined
            class="mb-2"
        ></v-select>
        <vue-dropzone
            ref="myVueDropzone"
            id="dropzone"
            :options="dropzoneOptions"
            @vdropzone-file-added="uploadTitleImage"
            @click="removeThisFile(UploadFile)"
        ></vue-dropzone>
        <v-row v-if="loading" class="d-flex justify-center mt-9">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
        <div class="mt-9">
            <v-row v-for="file in files" :key="file.filename">
                <v-col
                class="d-flex child-flex"
                cols="10"
                >
                <v-btn
                    outlined
                    :href="file.url"
                    target="_blank"
                >
                    <v-icon left>image</v-icon> {{file.previewText}}
                </v-btn>
                </v-col>
                <v-col
                class="d-flex child-flex"
                cols="2"
                >
                <v-btn
                    outlined
                    @click="deleteUpload(file.filename)"
                    :loading="deleting"
                    target="_blank"
                    color="error"
                >
                    <v-icon>delete</v-icon>
                </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
  </template>
  
<script>
import fb from "../main";
import crypto from "crypto";
import { truncate } from "lodash";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    props: ["record"],
    data: function() {
        return {
            deleting: false,
            loading: false,
            imageClassification: "Title",
            files: [],
            dropzoneOptions: {
                autoProcessQueue: false,
                url: '/upload',
                thumbnailWidth: 150,
                maxFilesize: 5,
                acceptedFiles: 'image/*',
                clickable: true,
                addRemoveLinks: true,
                maxFiles: 2,
                dictDefaultMessage: 'Drop image files here or click here to upload'
            }
        };
    },
    async created() {
        await this.getUploads();
    },
    watch: {
        // Watch for changes in the record prop
        record: {
            deep: true, // Watch for deep changes in nested properties
            handler() {
                this.getUploads(); // Call getUploads whenever the record changes
            }
        }
    },
    methods: {
        async getUploads(refresh) {
            this.loading = true;
            this.files = [];

            // Reset Dropzone files
            if (this.$refs.myVueDropzone) {
                this.$refs.myVueDropzone.removeAllFiles();
            }

            const storageRef = fb.storage.ref(`uploads/${this.record.zip}/${this.record.vehicleVin}`);
            try {
                // List all files in the directory
                const listResult = await storageRef.listAll();
                const fileNames = [];

                for (const itemRef of listResult.items) {
                    const filename = itemRef.name;
                    const url = await itemRef.getDownloadURL();

                    if (url) {
                        // Fetch the file as a blob
                        const response = await fetch(url);
                        const blob = await response.blob();

                        // Create a URL for the blob
                        const blobUrl = window.URL.createObjectURL(blob);

                        // Add the file to the list with the correct extension
                        this.files.push({
                            url: blobUrl,
                            filename: filename,
                            previewText: truncate(filename, { length: 15 }),
                        });
                        fileNames.push(filename);
                    }
                }

                if (refresh) {
                    // Only update uploads key if performing a refresh from a new upload or delete
                    await fb.db.collection("titleRequests").doc(this.record.id).update({ uploads: fileNames });
                }
            } catch (error) {
                console.error("Error fetching files:", error);
            }
            this.loading = false;
        },
        async uploadTitleImage(file) {
            const fileNameHash = crypto.createHash('md5').update(file.name).digest('hex');
            const fileExtension = file.name.split('.').pop(); // Extract the file extension
            const fileNamePrefix = this.imageClassification.toLowerCase().replace(/ /g, '-');
            const fileName = `${fileNamePrefix}-${fileNameHash}.${fileExtension}`; // Include the extension
            // Upload file to Firebase Storage
            const storageRef = fb.storage.ref();
            const storage = storageRef.child(`uploads/${this.record.zip}/${this.record.vehicleVin}/${fileName}`);
            try {
                // Use Firebase Storage's put method with progress tracking
                const uploadTask = storage.put(file);

                // Listen for state changes and progress
                uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Calculate the progress percentage
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    // Update Dropzone's progress bar
                    if (file.previewElement) {
                    file.previewElement.querySelector("[data-dz-uploadprogress]").style.width = `${progress}%`;
                    file.previewElement.querySelector("[data-dz-uploadprogress]").textContent = `${Math.round(progress)}%`;
                    }
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    // Handle unsuccessful uploads
                    console.error("Error during file upload:", error);
                    alert("There was an error uploading this file.");
                },
                async () => {
                    // Handle successful uploads on complete
                    console.log("Upload completed successfully.");
                    setTimeout(() => {
                        this.getUploads(true);
                    }, "1000");
                });
            } catch (error) {
                console.error("File upload failed:", error);
            }
        },
        async removeThisFile(file) {
            this.$refs.MyDropzone.removeFile(file)
        },
        async deleteUpload(filename) {
            if (window.confirm(`Are you sure you want to delete file ${filename}?`)) {
                this.deleting = true;
                const storageRef = fb.storage.ref();
                const storage = storageRef.child(`uploads/${this.record.zip}/${this.record.vehicleVin}/${filename}`);
                await storage.delete()
                this.deleting = false;
                this.getUploads(true);
            }
        },
    }
}
</script>
  
<style>
    .dropzone .dz-preview.dz-error .dz-error-mark {
      display: none;
    }
    .dropzone .dz-preview .dz-error-message {
      padding: 0 2px;
      top: 0;
      border: 0;
      font-size: 11px;
    }
    .vue-dropzone>.dz-preview .dz-progress .dz-upload {
      background: #4caf50;
      color: white;
      line-height: 16px;
      text-align: center;
    }
    .dropzone .dz-message {
        margin: 3em 0;
    }
</style>
