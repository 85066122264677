<template>
  <div id="app">
    <v-app>
      <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        v-if="privateRoute()"
        dark
      >
        <v-list dense>
          <template v-for="item in navItems">
            <v-list-item :key="item.text" link :to="item.to">
              <v-list-item-action>
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-divider></v-divider>
          <v-list-item link @click="signout">
            <v-list-item-action>
              <v-icon small>exit_to_app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Log out
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="primary"
        v-if="privateRoute()"
      >
        <v-toolbar-title class="ml-0">
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-toolbar-title>
        <img :src="logo" width="40px" class="ml-3">
        <v-spacer></v-spacer>
      </v-app-bar>

      <v-main class="content">
        <div class="pa-2">
          <router-view />
        </div>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
import logo from "@/assets/img/logo.png";

export default {
  name: "App",
  data: () => ({
    dialog: false,
    drawer: false,
    logo,
    allNavItems: [
      {
        icon: "home",
        text: "Home",
        to: "/",
        role: [],
      },
      {
        icon: "email",
        text: "Emails",
        to: "/emails",
        role: [],
      },
      {
        icon: "mdi-file-document",
        text: "Dealer Records",
        to: "/dealer-records",
        role: [],
      }
    ],
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$store.state;
    this.$store.watch(
      (state, getters) => getters.user,
      (newValue) => {
        this.navItems = this.allNavItems.filter((i) => {
          return i.role.includes(newValue.role) || !i.role.length;
        });
      }
    );
  },
  methods: {
    privateRoute() {
      return this.$route.meta.requiresAuth;
    },
    signout: async function () {
      try {
        await fb.auth.signOut();
        this.$router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    goHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        return;
      }
    },
  },
};
</script>

<style>
.content {
  background: whitesmoke;
}
.st0 {
  fill: #f97b00;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #f97b00;
}
</style>
